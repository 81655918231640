import {
	type LoaderFunctionArgs,
	json,
	type LinksFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useLocation,
} from '@remix-run/react'
import { useEffect } from 'react'
import {
	type Cart,
	MarketplaceProvider,
} from '#app/context/marketplace-provider'
import { useNonce } from '#app/epic-utils/nonce-provider.ts'
import { getSession } from '#app/services/session.server'
import stylesheet from '#app/tailwind.css?url'
import * as gtag from '#app/utils/gtags.client'
import packageJson from '../package.json'
import { getEnv } from './epic-utils/env.server.ts'
import fetchCart from './utils/crystallize/read/fetchCart'

export const links: LinksFunction = () => [
	{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
	{ rel: 'preconnect', href: 'https://fonts.gstatic.com' },
	{
		rel: 'stylesheet',
		href: 'https://fonts.googleapis.com/css2?family=Michroma&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap',
	},
	{ rel: 'stylesheet', href: stylesheet },
]

// Load the GA tracking id from the .env
export const loader = async ({ request }: LoaderFunctionArgs) => {
	const session = await getSession(request.headers.get('cookie'))
	const cartId = session.get('cartId')

	const ret = {
		initialCart: {},
		totalCartItems: 0,
		gaTrackingId: process.env.GA_TRACKING_ID,
		ENV: getEnv(),
	}

	if (!session || !cartId) {
		return json(ret)
	}

	try {
		console.log(process.env.CRYSTALLIZE_SHOP_API_TOKEN)
		const cart: Cart = await fetchCart({
			cartId,
		})

		const totalCartItems =
			cart?.items?.reduce((acc, item) => acc + item.quantity, 0) || 0

		return json({
			...ret,
			initialCart: cart,
			totalCartItems,
		})
	} catch (error: any) {
		// console.log('error', error)
		throw new Response((error && error?.errors?.[0]?.message) ?? error, {
			statusText: (error && error?.statusText) ?? 'Internal Server Error',
			headers: {
				cartId,
			},
			status: (error && error?.code) ?? 500,
		})
	}
}

export default function App() {
	const { initialCart, gaTrackingId, totalCartItems, ENV } =
		useLoaderData<typeof loader>()
	const location = useLocation()
	const nonce = useNonce()

	useEffect(() => {
		if (gaTrackingId?.length) {
			gtag.pageview(location.pathname, gaTrackingId)
		}
	}, [location, gaTrackingId])

	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{process.env.NODE_ENV !== 'production' && (
					<meta name="robots" content="noindex" />
				)}
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4b4b49" />
				<meta name="msapplication-TileColor" content="#4b4b49" />
				<meta name="theme-color" content="#ffffff" />
				<Meta />
				<Links />
			</head>
			<body className="min-h-full">
				{/* Removing GTM while troubleshooting hydration errors */}
				{/* {process.env.NODE_ENV !== 'production' || !gaTrackingId ? null : (
					<>
						<script
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
						/>
						<script
							async
							id="gtag-init"
							dangerouslySetInnerHTML={{
								__html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}');
                `,
							}}
						/>
					</>
				)} */}
				<script
					async
					dangerouslySetInnerHTML={{
						__html: `
						window.appVersion = '${packageJson.version}';
					`,
					}}
				/>
				<MarketplaceProvider
					initialState={{
						cart: initialCart,
						cartLoaded: !!initialCart,
						totalCartItems,
					}}
				>
					<Outlet />
				</MarketplaceProvider>
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(ENV)}`,
					}}
				/>
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	)
}
